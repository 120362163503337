@use "@angular/material" as mat;

@mixin color($config-or-theme) {
  // Extract the color configuration in case a theme has been passed.
  // This allows consumers to either pass a theme object or a color configuration.
  $config: mat.get-color-config($config-or-theme);

  // Extract the palettes you need from the theme definition.
  $primary: map-get($config, primary);
  $accent: map-get($config, accent);
  $warn: map-get($config, warn);
  $foreground: map-get($map: $config, $key: "foreground");

  .color-base-text {
    color: map-get($map: $foreground, $key: "base");
  }

  .color-primary-text {
    color: map-get($map: $foreground, $key: "text");
  }

  .color-secondary-text {
    color: map-get($map: $foreground, $key: "secondary-text");
  }

  .color-tertiary-text {
    color: map-get($map: $foreground, $key: "tertiary-text");
  }

  .color-base-inverse {
    color: map-get($map: $foreground, $key: "base-inverse");
  }

  .color-hint-text,
  .color-disabled-text {
    color: map-get($map: $foreground, $key: "hint-text");
  }

  .color-icon,
  .color-icons {
    color: map-get($map: $foreground, $key: "icon");
  }

  .color-divider,
  .color-dividers {
    color: map-get($map: $foreground, $key: "divider");
  }

  .color-primary {
    color: mat.get-color-from-palette($primary);
  }

  .color-primary-contrast {
    color: mat.get-contrast-color-from-palette($primary, 500);
  }

  .color-accent {
    color: mat.get-color-from-palette($accent);
  }

  .color-accent-contrast {
    color: mat.get-contrast-color-from-palette($accent, 500);
  }

  .color-warn {
    color: mat.get-color-from-palette($warn);
  }

  .color-warn-contrast {
    color: mat.get-contrast-color-from-palette($warn, 500);
  }

  @each $key, $color in $primary {
    @if $key != "contrast" and $key != "text" {
      .color-primary-#{$key} {
        color: $color;
      }
    }
  }

  @each $key, $color in $accent {
    @if $key != "contrast" {
      .color-accent-#{$key} {
        color: $color;
      }
    }
  }

  @each $key, $color in $warn {
    @if $key != "contrast" {
      .color-warn-#{$key} {
        color: $color;
      }
    }
  }
}

$colors: (
  "red": mat.$red-palette,
  "pink": mat.$pink-palette,
  "blue": mat.$blue-palette,
  "green": mat.$green-palette,
  "yellow": mat.$yellow-palette,
  "orange": mat.$orange-palette,
  "grey": mat.$grey-palette,
  "gray": mat.$gray-palette
);

@each $key, $color in $colors {
  .color-#{$key} {
    color: mat.get-color-from-palette($color, 500);
  }

  .color-#{$key}-contrast {
    color: mat.get-contrast-color-from-palette($color, 500);
  }

  @each $color-key, $color-value in $color {
    @if $color-key != "contrast" {
      .color-#{$key}-#{$color-key} {
        color: $color-value;
      }
      .color-#{$key}-#{$color-key}-contrast {
        color: mat.get-contrast-color-from-palette($color, $color-key);
      }
    }
  }
}

.color-ready {
  color: var(--ready-color);
}

.color-submitted {
  color: var(--submitted-color);
}

.color-quoted,
.color-quote {
  color: var(--quoted-color);
}

.color-referred,
.color-refer {
  color: var(--referred-color);
}

.color-declined,
.color-decline {
  color: var(--declined-color);
}

.color-bound {
  color: var(--bound-color);
}

.color-issued {
  color: var(--issued-color);
}

.color-lost {
  color: var(--lost-color);
}

.color-closed {
  color: var(--closed-color);
}

.color-missed {
  color: var(--missed-color);
}

.color-awaitinginformation {
  color: var(--awaitinginformation-color);
}

.color-inprogress {
  color: var(--inprogress-color);
}

// System Colors
.color-system-success {
  color: var(--system-success);
}

.color-system-danger {
  color: var(--system-danger);
}

.color-system-warn {
  color: var(--system-warn);
}

.color-system-info {
  color: var(--system-info);
}

.color-ready-contrast {
  color: white;
}

.color-submitted-contrast {
  color: #000 !important;
}

.color-bound-contrast {
  color: #000 !important;
}

.color-issued-contrast {
  color: #000 !important;
}

.color-quoted-contrast {
  color: #000 !important;
}

.color-referred-contrast {
  color: #000 !important;
}

.color-inprogress-contrast {
  color: #000 !important;
}

.color-declined-contrast {
  color: #000 !important;
}

.color-lost-contrast {
  color: rgba(black, 0.74);
}

.color-closed-contrast {
  color: rgba(black, 0.74);
}

.color-missed-contrast {
  color: #000;
}

.color-pending-contrast {
  color: #000;
}

.color-awaitinginformation-contrast {
  color: #000;
}

.color-white { color: white; }
.color-black { color: black; }
.color-inherit { color: inherit; }
.muted { opacity: 0.5; }
