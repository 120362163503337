@use "@angular/material" as mat;

@mixin theme($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: map-get($config, primary);
  $background: map-get($config, background);
  $foreground: map-get($config, foreground);

  .mat-table {
    background: transparent;

    .avatar {
      border-radius: 50%;
      width: 32px;
      min-width: 32px;
      height: 32px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-color: mat.get-color-from-palette($foreground, table-avatar);

      &.active {
        background-color: mat.get-color-from-palette($foreground, table-avatar-active);
      }
    }
  }

  .mat-header-row {
    border-bottom-width: 2px;
  }

  .mat-cell {
    border-bottom-color: mat.get-color-from-palette($foreground, "dividers");
  }

  // This is styling for the inline heading / labels in responsive tables
  .heading {
    min-width: 96px;
    margin-right: 16px;
  }

  .mat-sort-header-sorted .mat-sort-header-content {
    color: mat.get-color-from-palette($primary);
  }

  .mat-sort-header-arrow {
    color: mat.get-color-from-palette($primary);
  }

  .mat-row:hover {
    background: map-get($map: $background, $key: hover);
  }

  .mat-row::after,
  .mat-header-row::after,
  .mat-footer-row::after {
    content: none;
  }

  th.mat-header-cell.right-align {
    text-align: right;

    .mat-sort-header-container {
      justify-content: flex-end;
    }
  }
}
