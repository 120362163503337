@use "@angular/material" as mat;

@mixin color($config-or-theme) {
  // Extract the color configuration in case a theme has been passed.
  // This allows consumers to either pass a theme object or a color configuration.
  $config: mat.get-color-config($config-or-theme);

  // Extract the palettes you need from the theme definition.
  $primary: map-get($config, primary);
  $accent: map-get($config, accent);
  $warn: map-get($config, warn);
  $background: map-get(
    $map: $config,
    $key: "background",
  );

  .bg-color-default {
    background-color: map-get($map: $background, $key: "background");
  }

  .bg-color-card {
    background-color: map-get($map: $background, $key: "card");
  }

  .bg-color-secondary-card {
    background-color: map-get($map: $background, $key: "secondary-card");
  }

  .bg-disabled-button {
    background-color: map-get($map: $background, $key: "disabled-button");
  }

  .bg-binder-color {
    background-color: map-get($map: $background, $key: "binder-color");
  }

  .bg-color-primary {
    background-color: mat.get-color-from-palette($primary);
  }

  .bg-color-accent {
    background-color: mat.get-color-from-palette($accent);
  }

  .bg-color-warn {
    background-color: mat.get-color-from-palette($warn);
  }

  @each $key, $color in $primary {
    @if $key != "contrast" {
      .bg-color-primary-#{$key} {
        background-color: $color;
      }
    }
  }

  @each $key, $color in $accent {
    @if $key != "contrast" {
      .bg-color-accent-#{$key} {
        background-color: $color;
      }
    }
  }

  @each $key, $color in $warn {
    @if $key != "contrast" {
      .bg-color-warn-#{$key} {
        background-color: $color;
      }
    }
  }
}

$colors: (
  "red": mat.$red-palette,
  "pink": mat.$pink-palette,
  "blue": mat.$blue-palette,
  "green": mat.$green-palette,
  "yellow": mat.$yellow-palette,
  "orange": mat.$orange-palette,
  "grey": mat.$grey-palette,
  "gray": mat.$gray-palette,
);

@each $key, $color in $colors {
  .bg-color-#{$key} {
    background-color: mat.get-color-from-palette($color, 500);
  }

  @each $color-key, $color-value in $color {
    @if $color-key != "contrast" {
      .bg-color-#{$key}-#{$color-key} {
        background-color: $color-value;
      }
    }
  }
}

.bg-color-ready {
  background-color: var(--ready-color);
}

.bg-color-submitted {
  background-color: var(--submitted-color);
}

.bg-color-quoted,
.bg-color-quote {
  background-color: var(--quoted-color);
}

.bg-color-referred,
.bg-color-refer {
  background-color: var(--referred-color);
}

.bg-color-declined,
.bg-color-decline {
  background-color: var(--declined-color);
}

.bg-color-bound {
  background-color: var(--bound-color);
}

.bg-color-issued {
  background-color: var(--issued-color);
}

.bg-color-lost {
  background-color: var(--lost-color);
}

.bg-color-closed {
  background-color: var(--closed-color);
}

.bg-color-missed {
  background-color: var(--missed-color);
}

.bg-color-awaitinginformation {
  background-color: var(--awaitinginformation-color);
}

.bg-color-inprogress {
  background-color: var(--inprogress-color);
}

// System Colors
.bg-color-system-success {
  background-color: var(--system-success);
}

.bg-color-system-danger {
  background-color: var(--system-danger);
}

.bg-color-system-warn {
  background-color: var(--system-warn);
}

.bg-color-system-info {
  background-color: var(--system-info);
}

.bg-color-quoted-darker {
  background: rgb(68, 214, 44, 0.24);
}

.bg-color-referred-darker {
  background: rgb(255, 233, 20, 0.24);
}

.bg-color-declined-darker {
  background: rgb(255, 0, 0, 0.24);
}

.bg-color-white {
  background-color: white;
}

.bg-color-black {
  background-color: black;
}

.bg-color-inherit {
  background-color: inherit;
}

.bg-color-transparent {
  background-color: transparent;
}

.bg-color-agency-active {
  background-color: #43d62c38;
}

.bg-color-agency-pending {
  background-color: #ffd50031;
}

.bg-color-agency-inactive {
  background-color: rgba(255, 255, 255, 0.16);
}

.bg-color-agency-prospect {
  background-color: #ffd50031;
}
