@use "@angular/material" as mat;

@mixin color($config-or-theme) {
  // Extract the color configuration in case a theme has been passed.
  // This allows consumers to either pass a theme object or a color configuration.
  $config: mat.get-color-config($config-or-theme);

  // Extract the palettes you need from the theme definition.
  // $primary: map-get($config, primary);
  // $accent: map-get($config, accent);
  $background: map-get($map: $config, $key: "background");

  // Define any styles affected by the theme.
  .mat-card.secondary-card {
    // Use mat-color to extract individual colors from a palette.
    // background-color: mat-color($primary);
    // border-color: mat-color($accent, A400);
    background-color: map-get($map: $background, $key: "secondary-card");
  }
}
