// Import library functions for theme creation.
@use "@angular/material" as mat;

@mixin color($config-or-theme) {
  // Extract the color configuration in case a theme has been passed.
  // This allows consumers to either pass a theme object or a color configuration.
  $config: mat.get-color-config($config-or-theme);

  // Extract the palettes you need from the theme definition.
  $primary: map-get($config, primary);

  // $accent: map-get($config, accent);
  $foreground: map-get($config, foreground);
  $background: map-get($config, background);

  // Define any styles affected by the theme.
  .app-left-sidenav {
    @include mat.list-color($config-or-theme);

    .mat-nav-list {
      .mat-list-item,
      mat-list-item {
        a {
          &.nav-badge {
            background: mat.get-color-from-palette(mat.$grey-palette, 900);
            color: mat.get-contrast-color-from-palette(mat.$grey-palette, 900);
            padding: 0 6px;
            border-radius: 2px;
            min-width: 28px;
            text-align: center;
          }

          &.auto-nav-badge {
            background: mat.get-color-from-palette(mat.$grey-palette, 900);
            color: mat.get-contrast-color-from-palette(mat.$grey-palette, 900);
            padding: 0 8px;
            margin-right: 6px;
            border-radius: 2px;
            padding-left: 16px;
            text-align: right;
            background-image: url("../../../../../assets/vertigo-icons/vertigo-green-icon.png");
            background-size: 10px;
            background-repeat: no-repeat;
            background-position-x: 4px;
            background-position-y: center;
          }
        }

        &.active,
        &:hover {
          background: map-get($map: $background, $key: hover);

          a {
            color: mat.get-color-from-palette($primary) !important;

            &.nav-badge {
              background: mat.get-color-from-palette($primary);
              color: mat.get-contrast-color-from-palette($primary, 500) !important;
            }

            &.auto-nav-badge {
              background: mat.get-color-from-palette($primary);
              color: mat.get-contrast-color-from-palette($primary, 500) !important;
              background-image: url("../../../../../assets/vertigo-icons/vertigo-black-icon.png");
              background-size: 10px;
              background-repeat: no-repeat;
              background-position-x: 4px;
              background-position-y: center;
            }
          }
        }
      }

      .mat-expansion-panel {
        border-radius: 0 !important;
        background: transparent;
      }
    }
  }
}

@mixin typography($config-or-theme) {
  // Extract the typography configuration in case a theme has been passed.
  $config: mat.get-typography-config($config-or-theme);
}

@mixin theme($theme) {
  // Extracts the color and typography configurations from the theme.
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  .app-left-sidenav {
    .mat-expansion-panel-body {
      padding: 0 !important;
    }
  }

  // Do not generate styles if configurations for individual theming
  // systems have been explicitly set to `null`.
  @if $color {
    @include color($color);
  }

  @if $typography {
    @include typography($typography);
  }
}
